export default{
	state:{
		// token:null,
		// //用户信息
		// userInfo:{}	
        cartListNum: JSON.parse(sessionStorage.getItem('cartList')) ?  JSON.parse(sessionStorage.getItem('cartList')).length : ''
	},
	getters:{},
	mutations:{
        getCartListNum (state) {
            state.cartListNum = JSON.parse(sessionStorage.getItem('cartList')) ?  JSON.parse(sessionStorage.getItem('cartList')).length : ''
        }
	},
	actions:{},
	
}