import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/information',
    name: 'information',
    component: () => import(/* webpackChunkName: "information" */ '../views/information/Index.vue')
  },
  {
    path: '/deliveryInformation',
    name: 'deliveryInformation',
    component: () => import(/* webpackChunkName: "deliveryInformation" */ '../views/deliveryInformation/Index.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/privacyPolicy/Index.vue')
  },
  {
    path: '/termsConditions',
    name: 'termsConditions',
    component: () => import(/* webpackChunkName: "termsConditions" */ '../views/termsConditions/Index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/product/Index.vue')
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import(/* webpackChunkName: "catalog" */ '../views/catalog/Index.vue')
  },
  {
    path: '/shopping',
    name: 'shopping',
    component: () => import(/* webpackChunkName: "catalog" */ '../views/shopping/Index.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "catalog" */ '../views/checkout/Index.vue')
  },
  {
    path: '/gamePayment',
    name: 'gamePayment',
    // redirect: '/',
    component: () => import(/* webpackChunkName: "gamePayment" */ '../views/gamePayment/Index.vue')
  },
]

const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
