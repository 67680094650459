<template>
  <div class="home">
    <div>

      <el-main>
          <div class="largeImage">
            <div class="img-cover">
              <img src="../assets/home/home5.png" alt="">
            </div>
            <div class="title-con">
              <el-row :gutter="0">
                <el-col :span="18" :offset="3"
                  ><div class="title title-center">Brand-New NFT</div></el-col
                >
              </el-row>
              <el-row :gutter="0">
                <el-col :span="18" :offset="3"
                  ><div class="title title-center">Marketplace</div></el-col
                >
              </el-row>

              <div class="title-center title-btn flex justify-center">
                <button
                  class="buttonTitle buttons"
                  style="transform: scale(calc(130 / 100))"
                  @click="$router.push('/information')"
                >
                  KNOW MORE
                </button>
                <button @click="goto('AllProducts')" class="buttons" style="transform: scale(calc(130 / 100));margin-top: 30px;">EXPLORE</button>
              </div>
            </div>
          </div>
          <!-- tab4大类 -->
          <div class="tab-con webapp-pa">
            <div class="">
              <el-row v-if="isRefresh" :gutter="web ? 20 : 10">
                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                  <div @click="goto('ART')" class="img-con">
                    <img src="../assets/home/download1.jpg" alt="" />
                    <button>ART</button>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                  <div @click="goto('GAME')" class="img-con">
                    <img src="../assets/home/download2.jpg" alt="" />
                    <button>GAME</button>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                  <div @click="goto('PFPS')" class="img-con">
                    <img src="../assets/home/download3.jpg" alt="" />
                    <button>PFPS</button>
                  </div>
                </el-col>
                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                  <div @click="goto('PHOTOGRAPHY')" class="img-con">
                    <img src="../assets/home/download4.jpg" alt="" />
                    <button>PHOTOGRAPHY</button>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div
            class="webpc-pa conmaxwidth"
            :style="web ? 'margin:40px auto 0' : ''"
          >
            <el-row :gutter="10">
              <el-col :xs="24" :sm="7">
                <div class="title-wrapper" :style="web ? '' : 'padding:20px 20px 40px'">
                  <div class="h3">New Drops</div>
                  <div style="width: 100%" class="flex justify-center">
                    <div class="border"></div>
                  </div>
                  <p>
                    Discover the latest treasures in the NFT universe with our
                    New Drops section. Stay ahead of the curve and get your
                    hands on the most recent digital art, collectibles, and
                    unique virtual assets as soon as they're released. Don't
                    miss the opportunity to own a piece of the future today!
                  </p>
                  <button @click="goto('AllProducts')"class="buttons">SEE ALL DROPS <i class="el-icon-right"></i></button>
                </div>
              </el-col>
              <el-col :xs="24" :sm="17">
                <div class="swiper-container swiper-container1">
                  <div class="swiper-wrapper" style="padding-bottom: 20px;">
                    <div
                      v-for="item in homeList"
                      :key="item.id"
                      class="swiper-slide cursor-pointer"
                      @click="details(item)"
                    >
                      <div class="relative relative-hover">
                        <img :src="item.img" class="wh100" alt="" />
                        <div class="bgcolor space-evenly hover-con">
                          <!-- <i
                            class="el-icon-star-off font-22 btnhover ibtn text-white"
                          ></i> -->
                          <i @click.stop="addCart(item)"
                            class="el-icon-shopping-bag-2 font-22 btnhover ibtn text-white"
                          ></i>
                        </div>
                      </div>
                      <div class="swiper-name font-18">{{ item.name }}</div>
                      <div class="swiper-money font-16">{{ item.money }}</div>
                    </div>
                  </div>

                  <div v-if="web" class="prev">
                    <i class="iconfont icon-jiantou23 icon-fontClass"></i>
                  </div>
                  <div v-if="web" class="next">
                    <i class="iconfont icon-jiantou24 icon-fontClass"></i>
                  </div>

                  <div class="swiper-pagination"></div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="bgcolor webpc-pa">
            <div class="text-title"><i class="el-icon-position" style="margin-right:10px"></i> Free Fast AirDrop to your wallet</div>
          </div>
          <div
            class="webpc-pa conmaxwidth"
            :style="web ? 'margin:40px auto 0' : ''"
          >
            <el-row :gutter="10">
              <el-col :xs="24" :sm="17">
                <div class="Hottitle">Hot Collections</div>
                <div class="swiper-container swiper-container1">
                  <div class="swiper-wrapper" style="padding-bottom: 20px;">
                    <div
                      v-for="item in hotList"
                      :key="item.id"
                      class="swiper-slide cursor-pointer"
                      @click="details(item)"
                    >
                      <div class="relative relative-hover">
                        <img :src="item.img" class="wh100" alt="" />
                        <div class="bgcolor space-evenly hover-con">
                          <!-- <i
                            class="el-icon-star-off font-22 btnhover ibtn text-white"
                          ></i> -->
                          <i @click.stop="addCart(item)"
                            class="el-icon-shopping-bag-2 font-22 btnhover ibtn text-white"
                          ></i>
                        </div>
                      </div>
                      <div class="swiper-name font-18">{{ item.name }}</div>
                      <div class="swiper-money font-16">{{ item.money }}</div>
                    </div>
                  </div>

                  <!-- <div v-if="web" class="swiper-button-prev"></div> -->
                  <!-- <div v-if="web" class="swiper-button-next"></div> -->
                  <div v-if="web" class="prev">
                    <i class="iconfont icon-jiantou23 icon-fontClass"></i>
                  </div>
                  <div v-if="web" class="next">
                    <i class="iconfont icon-jiantou24 icon-fontClass"></i>
                  </div>

                  <div class="swiper-pagination"></div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="7">
                <div class="title-wrapper" :style="web ? 'margin-bottom: 20px' : 'padding:20px 20px 40px'">
                  <div class="h3">New Drops</div>
                  <div style="width: 100%" class="flex justify-center">
                    <div class="border"></div>
                  </div>
                  <p>
                    Discover the latest treasures in the NFT universe with our
                    New Drops section. Stay ahead of the curve and get your
                    hands on the most recent digital art, collectibles, and
                    unique virtual assets as soon as they're released. Don't
                    miss the opportunity to own a piece of the future today!
                  </p>
                  <button @click="goto('AllProducts')" class="buttons">HOT<i class="el-icon-right"></i></button>
                </div>
              </el-col>
            </el-row>
          </div>

          <div style="background:rgb(238, 238, 238)" :style="web?'padding:40px':''">
            <el-row :gutter="10" class="width100 flex-con">
              <el-col :xs="24" :sm="12" class="flex-con">
                <div class="title-wrapper" :style="web ? 'padding:40px' : 'padding:20px 20px 40px'">
                  <div class="h3">Specials</div>
                  <div style="width: 100%" class="flex justify-center">
                    <div class="border"></div>
                  </div>
                  <p>
                    Our Specials section is where rarity meets exceptional value. Uncover limited edition NFTs, exclusive drops, and special promotions curated just for you. Whether you're looking to invest or simply admire, this collection showcases the extraordinary pieces that stand out from the crowd.
                  </p>
                </div>
                <button @click="goto('AllProducts')" style="margin-bottom: 20px;" class="buttons">SPECIALS<i class="el-icon-right"></i></button>
              </el-col>
               <el-col :xs="24" :sm="12">
                <img class="wh100" :style="web?'':'width:calc( 100vw - 5px )'" src="../assets/home/detail.jpg" alt="">
                <!-- <img class="wh100" :style="web?'':'width:100vw'" src="../assets/home/detail.jpg" alt=""> -->
              </el-col>
            </el-row>
          </div>

          <div class="grid-row-bottom-2">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="24" :md="10">
                <div class="Hottitle">WHAT OUR CUSTOMERS SAY</div>
                <div class="swiper-container2 swiper-container">
                  <div class="swiper-wrapper" style="padding-bottom: 20px;">
                    <div
                      v-for="item in sayList"
                      :key="item.id"
                      class="swiper-slide swiper-slide-say"
                    >
                      <div class="relative relative-hover">
                      </div>
                      <div class="swiper-money font-16">{{ item.text }}</div>
                      <div class="swiper-name font-18">{{ item.name }}</div>
                    </div>
                  </div>

                  <!-- <div v-if="web" class="swiper-button-prev"></div>
                  <div v-if="web" class="swiper-button-next"></div> -->
                  <div v-if="web" class="prev">
                    <i class="iconfont icon-jiantou23 icon-fontClass"></i>
                  </div>
                  <div v-if="web" class="next">
                    <i class="iconfont icon-jiantou24 icon-fontClass"></i>
                  </div>

                  <div class="swiper-pagination"></div>
                </div>
              </el-col>
            </el-row>
          </div>

        </el-main>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { homeList, hotList, sayList } from "../json/home.js";

export default {
  name: "HomeView",
  data() {
    return {
      web: this.$utils.isDesktop(),
      isRefresh: true,
      homeList: homeList,
      hotList: hotList,
      sayList: sayList
    };
  },
  methods: {
    refresh() {
      this.isRefresh = false;
      this.$nextTick(() => {
        this.isRefresh = true;
      });
    },
    goto (title) {
      this.$router.push({
        path:'/catalog',
        query:{ title: title }
      })
    },
    addCart (item) {
      let cartList = []
      let arr = JSON.parse(sessionStorage.getItem('cartList'))
      if (arr && arr.length > 0) {
        arr.push(item)
        let map = new Map();
        for (let item of arr) {
            if (!map.has(item.id)) {
                map.set(item.id, item);
            };
        };
        arr = [...map.values()];
        sessionStorage.setItem('cartList', JSON.stringify(arr))
      } else {
        cartList.push(item)
        sessionStorage.setItem('cartList', JSON.stringify(cartList))
      }
      this.$store.commit('getCartListNum')
      // console.log(this.$store.state.user);
      // this.$router.go(0)
    },
    details (item) {
      this.$router.push({
        path:'/product',
        query:{ row: item }
      })
    },
  },
  mounted() {
    new Swiper(".swiper-container2", {
      slidesPerView: 1,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".next",
        prevEl: ".prev",
        disabledClass: 'my-button-disabled',
      },
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
    });
    new Swiper(".swiper-container1", {
      slidesPerView: 3,
      slidesPerGroup: 3,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        1280: {  //当屏幕宽度大于等于1280
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 20
        },
        768: {  //当屏幕宽度大于等于768
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 10,
        },
        320: {  //当屏幕宽度大于等于320
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 10,
        }
      },
      navigation: {
        nextEl: ".next",
        prevEl: ".prev",
        disabledClass: 'my-button-disabled',
      },
    });
  },
  components: {
  },
};
</script>
<style lang="less" scoped>
.nav {
  height: 75px;
  width: 554px;
}
.tab {
  height: 75px;
  display: flex;
  align-items: center;
}
.shadow-head {
  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.15);
  background: #fff;
}
button:hover {
  box-shadow: 0 1px 9px 0.6px rgba(0, 0, 0, 0.25);
  background: rgba(208, 30, 36, 1) !important;
}
.displayNone {
  display: none;
}
.red:hover {
  color: red;
  .displayNone {
    display: block;
    color: #000;
    height: 75px;
    top: 75px;
    background: #eee;
    width: 100vw;
  }
}
.tab-explore {
  padding: 0 12px;
  display: flex;
  align-items: center;
}
.tab-ul {
  height: 75px;
  font-weight: 400;
  li {
    padding: 0 40px;
  }
}
.tab-explore-p {
  margin-right: 6px;
}
.tab-icon-pr {
  margin-right: 20px;
}
.title-con {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate3d(-50%, -50%, 0);
}
.largeImage {
  height: calc( 100vh - 75px );
  overflow: hidden;
  position: relative;
  .img-cover {
    width: 100% ;
    height: 100%;
    img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
  .title {
    font-size: 110px;
    line-height: 110px;
    color: #fff;
    animation: size;
  }
  @media (max-width: 1200px) {
    .title {
      font-size: 80px;
      line-height: 80px;
    }
  }
  @media (max-width: 992px) {
    .title {
      font-size: 65px;
      line-height: 65px;
    }
  }
  @media (max-width: 768px) {
    .title {
      font-size: 45px;
      line-height: 45px;
    }
  }
  @media (max-width: 480px) {
    .title {
      font-size: 30px;
      line-height: 30px;
    }
  }
  .title-center {
    text-align: center;
  }
}
@media (max-width: 480px) {
  .title-btn button {
    padding: 8px 10px;
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  .title-btn .buttons {
    padding: 7px 12px !important
  }
}

.buttonTitle {
  margin-right: 80px;
  margin-top: 30px;
}
.tab-con {
  background: #000;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    filter: grayscale(1);
  }
}
.img-con {
  position: relative;
  button {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate3d(-50%, -50%, 0);
  }
}
.img-con:hover {
  transform: scale(1.05);
  img {
    filter: grayscale(0);
  }
  button {
    background: rgb(208, 30, 36);
  }
}

.title-wrapper {
  padding: 20px;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .h3 {
    font-family: monospace;
    font-weight: bold;
    font-size: 40px;
    color: rgba(41, 41, 43, 1);
  }
  p {
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    max-width: 700px;
    text-align: center;
  }
  .border {
    width: 50px;
    height: 3px;
    background: rgba(208, 30, 36, 1);
    margin-top: 15px;
    margin-bottom: 15px;
  }
  button {
    margin-top: 20px;
  }
}

.swiper-money {
  padding: 5px;
  text-align: center;
}
.swiper-name {
  font-family: monospace;
  padding: 5px;
  text-align: center;
}
.relative-hover .hover-con {
  // opacity: 0;
  opacity: 1;
  display: flex;
}
// .relative-hover:hover .hover-con {
//   opacity: 1;
//   transition: opacity 1s ease;
//   display: flex;
// }
.hover-con {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.ibtn {
  padding: 9px 11px;
}
.swiper-button-prev,
.swiper-button-next {
  background: rgba(41, 41, 43, 1);
  color: #fff;
  transform: scale(calc(0.5));
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: red;
}
.text-title {
  text-align: center;
  color: rgba(221, 221, 221, 1);
  text-transform: uppercase;
}
 .flex-con {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
 }

 .grid-row-bottom-2 {
  background-image: url(https://ebuynft.com/image/cache/catalog/Photography/Tranquil%20Dusk-1024x1024.jpg);
  background-attachment: fixed;
  background-size: cover;
  padding:100px
}
@media (max-width: 992px) {
  .grid-row-bottom-2 {
    padding: 20px;
    background-attachment: scroll !important
  }
}
@media (max-width: 992px) {
  .head-lg {
    display: none;
  }
}
@media (min-width: 993px) {
  .head-md {
    display: none;
  }
}
.swiper-slide-say {
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
//轮播图按钮
.swiper-container2 {
  .prev {
    top: 40%;
  }
  .next {
    top: 40%;
  }
}
.prev {
  position: absolute;
  top: 36%;
  left: 0;
  z-index: 2;
}
.next {
  position: absolute;
  top: 36%;
  right: 0;
  z-index: 2;
}
.icon-fontClass {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background: #29292b;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.icon-fontClass:hover {
  background: rgb(208, 30, 36)
}
.my-button-disabled{
  opacity: 0;
}
.buttons {
  align-items: center;
}
</style>