<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :width="web?'40%':'90%'"
      ref="dialog"
    >
      <div class="Hottitle">
        RETURNING CUSTOMER
      </div>
      <el-row :gutter="0" class="pb-md">
        <el-col :xs="24" :sm="12">
          <div>E-Mail Address</div>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-input v-model="user"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="0" class="pb-md">
        <el-col :xs="24" :sm="12">
          <div>Password</div>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-input v-model="password" type="password"></el-input>
        </el-col>
      </el-row>
      <div style="color:#337ab7" class="pb-md">Forgotten Password</div>
      <span slot="footer" class="dialog-footer">
        <button class="loginbtn">Login</button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        web: this.$utils.isDesktop(),
        user: '',
        password: '',
      };
    },
    methods: {
      open () {
        this.dialogVisible = true
      },
      close () {
        this.dialogVisible = false
      },
    }
  }
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  z-index: 9999998888;
}
/deep/.el-dialog__header {
  padding: 0;
}
/deep/.el-dialog__body {
  padding: 15px 15px 0 15px;
}
.pb-md {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
}
.loginbtn {
  width: 100%;
  padding: 8px 0;
  text-align: center;
}
/deep/.el-input__inner {
  height: 36px !important;
  line-height: 36px !important
}
</style>